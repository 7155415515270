"use strict";
document.addEventListener("DOMContentLoaded", function(){

// RESIZE

function checkWindowWidth() {
  let isSmallScreen = false;

  function handleResize() {
    if (window.innerWidth < 768) {
      return isSmallScreen = true;
    } else {
      return isSmallScreen = false;
    }
  }

  // Initial check on page load
  return handleResize();
}

// TRACK IF AN ELEMENT IS HALFWAY INSIDE THE VIEWPORT

function addClassWhenHalfwayInView(targetElement, className) {

  function isElementHalfwayInsideViewport() {
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
    const halfwayPoint = viewportHeight / 2;
    const rect = targetElement.getBoundingClientRect();
    const elementTop = rect.top;
    const elementBottom = rect.bottom;

    return elementTop < halfwayPoint && elementBottom > 0;
  }

  function handleScroll() {
    if (isElementHalfwayInsideViewport()) {
      targetElement.classList.add(className);
    } else {
      targetElement.classList.remove(className);
    }
  }
  
window.addEventListener('scroll', handleScroll);
  handleScroll();
}

// MAIN NAVIGATION

const body = document.querySelector('body');
// const viewportWidth = window.innerWidth;
// const breakpointWidth = 768;

const pgHeader = document.querySelector('.pg-header');
const toggleNav = document.querySelector('.toggle-nav');
const toggleLanguageSwitcher = document.querySelector('.toggle-location-switcher');
const toggleSignUpAlerts = document.querySelector('.main-nav .btn');
toggleSignUpAlerts.addEventListener('click', function () {
  pgHeader.classList.remove('main-nav-is-open');
  body.classList.remove('no-scroll');
});
toggleNav.addEventListener('click', function(){
  pgHeader.classList.remove('location-switcher-is-open');
  pgHeader.classList.toggle('main-nav-is-open');
  // body.classList.toggle('no-scroll');

  const isMobile = checkWindowWidth();

  if (!!isMobile || body.classList.contains('no-scroll')) {
    body.classList.toggle('no-scroll');
  }
});

toggleLanguageSwitcher.addEventListener('click', function(){
  pgHeader.classList.remove('main-nav-is-open');
  pgHeader.classList.toggle('location-switcher-is-open');

  const isMobile = checkWindowWidth();

  if (!!isMobile || body.classList.contains('no-scroll')) {
    body.classList.toggle('no-scroll');
  }
  
});

// TELEPHONE NUMBER DROPDOWN


const toggleTelPrefixes = document.querySelector('.tel-prefix__toggle');
const telPrefixes = document.querySelector('.tel-prefix__list');

if(toggleTelPrefixes) {

  toggleTelPrefixes.addEventListener('click', function(){
    telPrefixes.classList.toggle('is-open');
  });

}

const phonePrefixes = document.querySelectorAll('.tel-prefix__list li button');

phonePrefixes.forEach((prefix) => {
  prefix.addEventListener('click', (e) => {
    e.preventDefault();

    document.getElementById('selected-flag').innerHTML = prefix.querySelector('.tel-prefix__flag').innerHTML;
    document.getElementById('selected-prefix').innerHTML = prefix.querySelector('.tel-prefix__label').innerHTML;
    document.getElementById('tel-prefix').value = prefix.querySelector('.tel-prefix__label').innerHTML;

    // Remove the current value when the prefix is changed
    document.getElementById('tel').value = '';

    document.getElementById('tel').setAttribute('maxlength', prefix.querySelector('.tel-prefix__label').dataset.maxlength)
    document.getElementById('tel').pattern = prefix.querySelector('.tel-prefix__label').dataset.telpattern;
    telPrefixes.classList.toggle('is-open');
  });
});

// LANGUAGE SWITCHER

var lang_options = {"GB":["English"],"IE":["English"],"FR":["English","French"],"IT":["English","Italian"],"ES":["English","Spanish"],"DE":["English","German","Polish","Turkish","Spanish","Romanian","Bulgarian","Czech"],"AT":["English","German"],"BE":["English","French","Dutch"],"NL":["English","Dutch"]};

var locations = document.querySelectorAll('input[name="location"]');
var languages = document.querySelectorAll('.language-switcher-list li');

locations.forEach((location) => {
  let defaultLang = location.getAttribute('data-default-lang');

  location.addEventListener('click', function(){
    languages.forEach((location) => {
      location.style.display = 'none';
    });

    lang_options[location.value].forEach((language) => {
      document.querySelector("[data-language='" + language + "']").style.display = 'block';
    });

    document.querySelector("#" + defaultLang + "-language-switch").checked = true;
  });
});

document.getElementById('switcher-submit').addEventListener('click', function(e){
  e.preventDefault();

  let location = document.querySelector('input[name="location"]:checked').value;
  let language = document.querySelector('input[name="language"]:checked').value;

  let role_url = '';
  if(typeof role_id !== 'undefined') {
    role_url = '/' + role_id
  }

  if(language == 'en') {
    window.location.href = '/' + pageName + role_url + '/?location=' + location;
    // console.log('/' + pageName + role_url + '/?location=' + location);
  } else {
    window.location.href = '/' + language + '/' + pageName + role_url + '/?location=' + location;
    // console.log('/' + language + '/' + pageName + role_url + '/?location=' + location);
  }
});


// GET CURRENT LOCATION

var getLocation = document.getElementById('get-location');

if(getLocation) {
  getLocation.addEventListener('click', function(e){
    console.log('here first');
    e.preventDefault();

    if ("geolocation" in navigator) {
      console.log('here second');
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log('here third');
          const apiKey = 'AIzaSyCoIJwYY2BGKklhrcdAjxmAC5-P56tLRbg';
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          // Prepare the API request URL
          const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
            
          // Send the API request using fetch
          fetch(url)
            .then(response => response.json())
            .then(data => {
              console.log('here fourth');
              // Check if the API call was successful
              if (data.status === 'OK') {
                // Get the first result
                const firstResult = data.results[0];

                // Find the postal town in the address components
                let postalTown = '';
                for (const component of firstResult.address_components) {
                  if (component.types.includes('postal_town')) {
                    postalTown = component.long_name;
                    break;
                  }
                }

                // Output the postal town
                if (postalTown) {
                  console.log('here fifth');
                  // document.querySelector('input[name="search_location"]').value = postalTown;
                  const searchLocationField = document.querySelector('input[name="search_location"]');
                  searchLocationField.value = postalTown;
                  searchLocationField.focus();
                  
                } else {
                  //('Postal town not found for the provided coordinates.');
                }
              } else {
                //console.log('Error: Unable to fetch data from the Google Maps API.');
              }
            })
            .catch(error => console.error('Error:', error));
        },
        (error) => {
          console.error('Error getting geolocation:', error);
        }
      );
    } else {
      console.error('Geolocation is not available in this browser.');
    }
  });
}

// APPLICATION PROCESS

const applicationStepsOuter = document.querySelector('.application-process-outer');

if(applicationStepsOuter){
  addClassWhenHalfwayInView(applicationStepsOuter, 'is-animating');
}

// SIDE SCROLLER

const featuredRolesScrollers = document.querySelectorAll('.featured-roles');

featuredRolesScrollers.forEach((el) => {
  const sideScrollerNext = el.querySelector('.side-scroller-control__next');
  const sideScrollerPrev = el.querySelector('.side-scroller-control__prev');
  const scroller = el.querySelector('.side-scroller');

  if(sideScrollerNext) {
    sideScrollerNext.addEventListener('click', function() {
      scroller.scrollLeft += 350;
    });
  }
  if(sideScrollerPrev) {
    sideScrollerPrev.addEventListener('click', function() {
      scroller.scrollLeft -= 350;
    });
  }

});

const driverStoriesScrollers = document.querySelectorAll('.driver-stories');

driverStoriesScrollers.forEach((el) => {
  const sideScrollerNext = el.querySelector('.side-scroller-control__next');
  const sideScrollerPrev = el.querySelector('.side-scroller-control__prev');
  const scroller = el.querySelector('.side-scroller');

  sideScrollerNext.addEventListener('click', function() {
    scroller.scrollLeft += 350;
  });
  
  sideScrollerPrev.addEventListener('click', function() {
    scroller.scrollLeft -= 350;
  });

});

// ACCORDION

let openCurrentAccordionBlock = '';
const accordions = document.querySelectorAll('.accordion-block');
const accordionToggles = document.querySelectorAll('.accordion-block__toggle');

accordions.forEach(function (el) {
  const currentAccordionToggle = el.querySelector('.accordion-block__toggle');
  const currentAccordionAnswer = el.querySelector('.accordion-block__answer');
  
    currentAccordionToggle.addEventListener('click', function(){
      
      // remove is-active from all here
      accordionToggles.forEach(function (el) {
        el.classList.remove('is-active');
      });
      
      const allAccordionAnswers = document.querySelectorAll('.accordion-block__answer');
      
      allAccordionAnswers.forEach(function(el) {
        el.classList.remove('is-open');
      });
      
      if(openCurrentAccordionBlock != currentAccordionAnswer.id) {
        currentAccordionAnswer.classList.add('is-open');
        // then add is-active to the clicked one
        currentAccordionToggle.classList.add('is-active');
        openCurrentAccordionBlock = currentAccordionAnswer.id;
      } else {
        openCurrentAccordionBlock = '';
      }
    });
    
});

// NEWSLETTER MODAL

const newsletterModalToggles = document.querySelectorAll('.toggle-newsletter-modal');
const newsletterModal = document.querySelector('.modal-overlay');

newsletterModalToggles.forEach((toggle) => {
  toggle.addEventListener('click', function(){
    newsletterModal.classList.toggle('is-visible');
    body.classList.toggle('no-scroll');
  });
});

// ANIMATED STATS

const statsGrid = document.querySelector('.stats-grid-outer');

if(statsGrid) {
  addClassWhenHalfwayInView(statsGrid, 'is-animating');
}

if(statsGrid) {

const statNumbers = document.querySelectorAll('.stat__number');

const observer = new IntersectionObserver(entries => {
  entries.forEach(entry => {
    statNumbers.forEach(function(stat){
      const statVal = parseFloat(stat.getAttribute('data-value'));
      const countUp = new CountUp(stat, 0, statVal, 0, 3);
      if (entry.isIntersecting) {
        countUp.start();
      } else {
        countUp.reset();
      }
    });
  });
});

observer.observe(statsGrid);

}

// SHOW/HIDE MAP

const rolesParent = document.querySelector('.roles-outer');
const openMap = document.querySelector('.open-map');
const closeMap = document.querySelector('.close-map');
const rolesContainer = document.querySelector('.roles-list');
const mapContainer = document.querySelector('.roles-map-outer');
const map = document.querySelector('.roles-map');

if(rolesContainer) {

  const rolesContainerHeight = rolesContainer.offsetHeight;
  const mapHeight = map.offsetHeight;

  function handleViewportChange(mq) {
    if (mq.matches) {

      openMap.addEventListener('click', function() {
        rolesParent.classList.add('map-is-visible');
      
        if(mapHeight >= rolesContainerHeight) {
          rolesContainer.style.height = mapHeight + "px";
        } else {
          mapContainer.style.height = rolesContainerHeight + "px";
          map.style.position = "sticky";
        }
      
      });
      
      closeMap.addEventListener('click', function() {
        rolesParent.classList.remove('map-is-visible');
      
        rolesContainer.style.height = '';
        mapContainer.style.height = '';
        map.style.position = '';
      });

    } else {
      openMap.addEventListener('click', function() {
        rolesParent.classList.add('map-is-visible');
        rolesContainer.style.height = mapHeight + "px";
      });
      
      closeMap.addEventListener('click', function() {
        rolesParent.classList.remove('map-is-visible');
        rolesContainer.style.height = '';
      });
    }
  }

  function handleClick() {
    console.log('Element clicked!');
    // Add other actions when the element is clicked
  }

  const mediaQuery = window.matchMedia('(min-width: 768px)');
  handleViewportChange(mediaQuery);  // Initial check

  // Add event listener for viewport changes
  mediaQuery.addEventListener('change', (event) => {
    handleViewportChange(event.currentTarget);
  });

}

// BACK TO TOP

const backToTop = document.querySelector('.back-to-top');

if(backToTop) {
  backToTop.addEventListener('click', function(){
    window.scrollTo(0, 0);
    location.reload();
  });
}

// URL decoration

(function() {
  var domainsToDecorate = [
          'driverexpress.com', //add or remove domains (without https or trailing slash)

      ],
      queryParams = [
          'utm_medium', //add or remove query parameters you want to transfer
          'utm_source',
          'utm_campaign',
          'utm_term',
          'utm_content',
      ]
  // do not edit anything below this line
  var links = document.querySelectorAll('a');

 

// check if links contain domain from the domainsToDecorate array and then decorates
  for (var linkIndex = 0; linkIndex < links.length; linkIndex++) {
      for (var domainIndex = 0; domainIndex < domainsToDecorate.length; domainIndex++) { 
          if (links[linkIndex].href.indexOf(domainsToDecorate[domainIndex]) > -1 && links[linkIndex].href.indexOf("#") === -1) {
              links[linkIndex].href = decorateUrl(links[linkIndex].href);
          }
      }
  }
// decorates the URL with query params
  function decorateUrl(urlToDecorate) {
      var originalURL = urlToDecorate;
      urlToDecorate = (urlToDecorate.indexOf('?') === -1) ? urlToDecorate + '?' : urlToDecorate + '&';
      var collectedQueryParams = [];
      for (var queryIndex = 0; queryIndex < queryParams.length; queryIndex++) {
          if (getQueryParam(queryParams[queryIndex])) {
              collectedQueryParams.push(queryParams[queryIndex] + '=' + getQueryParam(queryParams[queryIndex]))
          }
      }
      if(collectedQueryParams.length) {
        return urlToDecorate + collectedQueryParams.join('&');
      } else {
        return originalURL;
      }

      
  }

 

  // borrowed from https://stackoverflow.com/questions/831030/
  // a function that retrieves the value of a query parameter
  function getQueryParam(name) {
      if (name = (new RegExp('[?&]' + encodeURIComponent(name) + '=([^&]*)')).exec(window.location.search))
          return decodeURIComponent(name[1]);
  }


  })();

  document.getElementById('myDropzone').addEventListener('dragover', (e) => {
    e.preventDefault()
  });

  document.getElementById('myDropzone').addEventListener('drop', (e) => {
    document.getElementById('file').files = e.dataTransfer.files;
    e.preventDefault()
  });

  document.getElementById('myDropzone').addEventListener('click', (e) => {
    e.preventDefault();
    document.getElementById('file').click();
  });

  document.getElementById('file').addEventListener('change', function(event) {
    const file = event.target.files[0];
    const validTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
    const fileInfoElement = document.getElementById('fileInfo');

    if (file) {
      // Calculate file size in MB and format it to two decimal places
      var fileSizeMB = (file.size / 1024 / 1024).toFixed(2);
      var fileInfoText = `${file.name} - ${fileSizeMB}MB`;

      // Check if the file type is valid
      if (!validTypes.includes(file.type)) {
          alert('Invalid file type. Only PDF and DOC/DOCX files are allowed.');

          // Clear the selected file
          event.target.value = ''; 

          // Clear the file info text
          fileInfoElement.innerText = ''; 
      } else {
          // Update the text of the uploadedFile element
          fileInfoElement.innerText = fileInfoText;
      }
    } else {
        // Clear the file info text if no file is selected
        fileInfoElement.innerText = fileInfo.dataset.uploadfiletext ? fileInfo.dataset.uploadfiletext : 'Upload file';
    }
  });

  // var myDropzone = new Dropzone("#myDropzone", {
  //   url: "/upload.php",
  //   paramName: "file", // The name that will be used to transfer the file
  //   maxFilesize: 15, // MB
  //   maxFiles: 1, // Max number of files
  //   acceptedFiles: ".jpg,.jpeg,.png,.pdf", // Allowed file types
  //   dictDefaultMessage: `
  //     <span class="dropzone-icon">
  //       <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><path d="m67.74 0h-55.56v100s75.65 0 75.65 0v-79.91zm1.16 5.28 14.14 14.14h-14.14zm-53.82 91.81v-94.18h50.91v19.41h18.93v74.76h-69.84z"/><path d="m22.63 14.22h30.8v2.91h-30.8z"/><path d="m22.63 26.17h17.44v2.91h-17.44z"/><path d="m44.71 26.17h17.44v2.91h-17.44z"/><path d="m22.63 38.12h54.29v2.91h-54.29z"/><path d="m58.69 53.05h18.22v2.91h-18.22z"/><path d="m22.63 67.98h54.27v2.91h-54.27z"/><path d="m22.63 53.05h30.8v2.91h-30.8z"/><path d="m49.77 82.92h27.15v2.91h-27.15z"/></svg>
  //       <p class="title title--sm">Upload file</p>
  //     </span>
  //     or drag and drop files here - max file size: 15MB
  //     `,
  //   addRemoveLinks: true // Adds a remove link for each file
  // });

  // Add event listeners for when files are added or removed
  // myDropzone.on("addedfile", function(file) {
  //   console.log("File added:", file);
  // });

  // myDropzone.on("removedfile", function(file) {
  //   console.log("File removed:", file);
  // });

});

function onSubmitFormApplication(token) {
    let error = false;

    const errors = document.querySelectorAll('.form-row-error');
    errors.forEach(error => {
      error.style.display = 'none';
    });

    const first_name = document.querySelector('input[name="first_name"]');
    const last_name = document.querySelector('input[name="last_name"]');

    const email = document.querySelector('input[name="email"]');
    const emailPattern = email.getAttribute('pattern');
    const emailRegex = new RegExp(emailPattern);

    const phone = document.querySelector('input[name="phone"]');
    const phonePattern = phone.getAttribute('pattern');
    const phoneRegex = new RegExp(phonePattern);

    const postcode = document.querySelector('input[name="postcode"]');
    const postcodePattern = postcode.getAttribute('pattern');
    const postcodeRegex = new RegExp(postcodePattern);

    // const referral_code = document.querySelector('input[name="referral_code"]');
    const weekends = document.getElementsByName('weekends');
    const self_employed = document.getElementsByName('self-employed');

    const start_date = document.querySelector('input[name="start_date"]');
    const start_date_min_date = new Date(start_date.getAttribute('min'));

    const contact = document.querySelector('input[name="contact"]');

    const file = document.getElementById('file');
    const fileMaxSize = 15 * 1024 * 1024;

    if(!first_name.value) {
      document.querySelector('#fn-error').style.display = 'block';
      error = true;
    }

    if(!last_name.value) {
      document.querySelector('#ln-error').style.display = 'block';
      error = true;
    }

    if(!email.value) {
      document.querySelector('#email-error').style.display = 'block';
      error = true;
    }

    if(!emailRegex.test(email.value)) {
      document.querySelector('#email-error').style.display = 'block';
      error = true;
    }

    if(!phone.value || !phoneRegex.test(phone.value)) {
      document.querySelector('#phone-error').style.display = 'block';
      error = true;
    }

    if(phone.value.startsWith('0')) {
      document.querySelector('#phone-error-starts-with-zero').style.display = 'block';
      error = true;
    }

    if(!postcode.value || !postcodeRegex.test(postcode.value)) {
      document.querySelector('#postcode-error').style.display = 'block';
      error = true;
    }

    if(file.files.length >= 1 && file.files[0].size > fileMaxSize) {
      document.getElementById('file-size-error').style.display = 'block';
      error = true;
    }

    if(weekends.length) {
      let selectedWeekendValue = null;
    
      for (var i = 0; i < weekends.length; i++) {
        if (weekends[i].checked) {
          selectedWeekendValue = weekends[i].value;
          break;
        }
      }
      
      // if(selectedWeekendValue != "yes") {
      //   document.querySelector('#weekends-error').style.display = 'block';
      //   error = true;
      // }
    }


    if(self_employed.length) {
      let selectedSelfEmployedValue = null;
      
      for (var i = 0; i < self_employed.length; i++) {
        if (self_employed[i].checked) {
          selectedSelfEmployedValue = self_employed[i].value;
          break;
        }
      }

      // if(!selectedSelfEmployedValue) {
      //   document.querySelector('#self-employed-error').style.display = 'block';
      //   error = true;
      // }
    }

    if(!start_date.value || new Date(start_date.value) < start_date_min_date) {
      document.querySelector('#start-date-error').style.display = 'block';
      error = true;
    }

    if(!contact.checked) {
      document.querySelector('#contact-error').style.display = 'block';
      error = true;
    }

    if(error) {
      e.preventDefault();
    } else {
      if (token) {
        document.getElementById("application-form").submit();
      } else {
        console.error('Error: Invalid reCAPTCHA token', token);
      }
    }
    
  // });
}

// EXTENDED FORM VALIDATIONS

document.getElementById('tel').addEventListener('input', function(event) {
  const inputField = event.target;
  const pattern = inputField.getAttribute('pattern');
  const regex = new RegExp(pattern);

  const warningInvalidPattern = document.getElementById('phone-error');
  const warningZeroStart = document.getElementById('phone-error-starts-with-zero');

  // Remove any non-digit characters
  inputField.value = inputField.value.replace(/\D/g, '');

  // Check if the input starts with '0'
  if (inputField.value.startsWith('0')) {
    warningZeroStart.style.display = 'block';
  } else {
    warningZeroStart.style.display = 'none';
  }

  // Check if the input follows the pattern
  if (!regex.test(inputField.value)) {
    warningInvalidPattern.style.display = 'block';
  } else {
    warningInvalidPattern.style.display = 'none';
  }
});

// document.getElementById('file').addEventListener('change', function(event) {
//   const file = event.target.files[0];
//   const validTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
//   const maxSize = 15 * 1024 * 1024;

//   if (file) {
//       const fileSize = file.size; 

//       if (fileSize > maxSize) {
//         document.getElementById('file-size-error').style.display = 'block';
//       } else {
//         document.getElementById('file-size-error').style.display = 'none';
//       }
      
//       if (!validTypes.includes(file.type)) {
//         document.getElementById('file-invalid-type-error').style.display = 'block';
//       } else {
//         document.getElementById('file-invalid-type-error').style.display = 'none';
//       }
//   }
// });

// MODAL

document.getElementById('closeExistingApplicationModal').addEventListener('click', function(event) {
  document.getElementById('ExistingApplicationModal').style.display = 'none';
});